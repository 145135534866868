import {EventSender} from "../event-sender.interface";
import {environment} from "../../../../environments/environment";

declare const gtag: Function;

export class GtagEventSender implements EventSender {

    googleTagEvents = new Map<string, string>([
        ["PageView", "PageView"],
        ["register", "Register"],
        ["login", "Login"],
        ["desk-record", "Record"],
        ["buy-plan", "Subscribe"],
        ["checkout-dismissed", "CheckoutDismissed"],
        ["checkout-completed", "CheckoutCompleted"],
    ]);

    init(userProperties: {}): void {
    }

    identify(userId: string): void {
    }

    log(event: string, data?: any): void {
        if (environment.production) {
            const eventName = this.googleTagEvents.get(event);

            eventName && gtag('event', eventName, data);
        }
    }

    logRevenue(plan: string, price: number): void {
    }

    logPageView(properties: {} = {}): void {
        environment.production && this.log('PageView', properties);
    }

    updateRegister(attr?: string, value?: string): void {
    }

    clear(): void {
    }

}