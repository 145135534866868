import {EventSender} from "../event-sender.interface";
import mixpanel from 'mixpanel-browser';
import {environment} from "../../../../environments/environment";
import {NgCookieService} from "../../ng-cookie.service";
import {AbTestManagerService} from "../../ab-test-manager.service";
import {Inject, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";
import moment from "moment";

export class MixpanelEventSender implements EventSender {

    initializePromise: Promise<void>;

    constructor(
        private optOut: boolean = false,
        private cookieService: NgCookieService,
        private abTestManagerService: AbTestManagerService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        this.initializePromise = new Promise((resolve) => {
            mixpanel.init(environment.globals.mixpanelId, {
                debug: !environment.production, // Enable/disable debug mode as needed
                persistence: 'cookie',
                disable_cookie: false,
                batch_requests: true, // Optional: Use batch requests for better performance,
                autotrack: true,
                cookie_domain: environment.production ? '.bigvu.tv' : undefined
            });

            // set super property once and another
            mixpanel.register_once({startPlatform : 'Web'})
            // set  property for current session
            mixpanel.register({ platform : 'Web'});

            if (isPlatformBrowser(this.platformId)) {
                this.setTestUserProperties().then(resolve);
            } else {
                resolve();
            }

            if (this.optOut) {
                mixpanel.opt_out_tracking();
            }
        });
    }

    async init(userProperties: {}): Promise<void> {
        await this.initializePromise;
        mixpanel.people.set({
            ...userProperties,
            "platform": "Web"
        });
        mixpanel.people.set_once({
            "startPlatform": "Web"
        });
    }

    async identify(userId: string): Promise<void> {
        await this.initializePromise;
        mixpanel.identify(userId);
    }

    async log(event: string, data?: any): Promise<void> {
        await this.initializePromise;
        mixpanel.track(event, Object.assign(data, {hour : this.getTruncatedHourUTC()}));
    }

    async logRevenue(plan: string, price: number): Promise<void> {
        // await this.initializePromise;
        // mixpanel.track('Revenue', {
        //     product_id: plan,
        //     price: price,
        //     quantity: 1,
        // });
    }

    async logPageView(properties: {} = {}): Promise<void> {
        await this.initializePromise;
        mixpanel.track('PageView', Object.assign(properties, {hour : this.getTruncatedHourUTC()}) );
    }

    private async setTestUserProperties() {
        const abTestValues = await this.abTestManagerService.getAbTestValues();
        if (abTestValues.key && abTestValues.value) {
            mixpanel.people.set({
                [abTestValues.key]: abTestValues.value,
            });
        }
    }

    private getTruncatedHourUTC() {
        const now = new Date();
        const utcHour = now.getUTCHours();
        return `${utcHour.toString().padStart(2, '0')}:00`;
    }


    clear() {
        mixpanel.unregister('device_id');
        mixpanel.unregister('platform');
    }

}